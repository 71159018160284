import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useToast } from "vue-toastification/composition";

export default function useProductCombinationList() {
  // Use toast
  const { t } = useI18nUtils();
  const toast = useToast();

  const refProductCombinationListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", sortable: true },
    //{ key: "abbreviation", label: t("Abbreviation") },
    { key: "code", label: t("Code"), sortable: true },
    //{ key: "with_preparations", label: t("With preparations") },
    { key: "product_shortcut", label: t("Combination"), sortable: true },
    { key: "active", label: t("Is active") , sortable: true},
  ];
  const perPage = ref(10);
  const totalProductCombination = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refProductCombinationListTable.value
      ? refProductCombinationListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProductCombination.value,
    };
  });

  const refetchData = () => {
    refProductCombinationListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchProductCombination = (ctx, callback) => {
    instance
      .get("/parameters/product-combinations/", {
        params: {
          page: currentPage.value,
          all: "all",
          search: searchQuery.value,
          sort: sortBy.value,
          sortDir: isSortDirDesc.value,
        },
      })
      .then((response) => {
        const { count, results } = response.data;
        totalProductCombination.value = count;
        callback(results);
      })
      .catch((err) => {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        toast({
          component: ToastificationContent,
          props: {
            title: t("Error"),
            text: error ?? t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchProductCombination,
    tableColumns,
    perPage,
    currentPage,
    totalProductCombination,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductCombinationListTable,
    refetchData,
    t,
  };
}
